import {appSyncQuery} from './index';
import * as gql from "../constants/GraphQL";

const listString = `query listMerchants($direction: MoveDirection, $limit: Int, $offset: String, $offset_id: String, $query: SqlQuery) {
  merchants(direction: $direction, 
            limit: $limit, 
            offset: $offset, 
            offset_id: $offset_id, 
            query: $query) {
    items {
      ach_active
      card_active
      cash_active
      is_system
      merchant_name
      merchant_uid
      parent_merchant_uid
      submitted_onboarding
    }
    total_row_count
  }
}
`

export const list = (order, offset, limit, filter, direction) => {
  let sort = [{key: 'merchant_name', direction: order}]
  let formattedFilter = filter ? {key: 'merchant_name', value: `%${filter}%`, operator: gql.LIKE } : null
  const queryObject = {
    query_list: formattedFilter,
    sort_list: sort,
  }
  const variables = {
    query: queryObject,
    offset_id: offset?.merchant_uid,
    offset: offset?.merchant_name,
    limit: limit,
    direction: direction
  }
  return appSyncQuery(listString, variables)
}

export const listSystemMerchants = () => {
  let sort = [{key: 'merchant_name', direction: gql.DESC}]
  let formattedFilter = {key: 'is_system', value: false, operator: gql.EQUAL_TRUE }
  const queryObject = {
    query_list: formattedFilter,
    sort_list: sort,
  }
  const variables = {
    query: queryObject,
    limit: 100
  }
  return appSyncQuery(listString, variables)
}

const queryString = `query getMerchant($merchant_uid: String) {
  merchant(merchant_uid: $merchant_uid) {
    merchant_uid
    merchant_name
    api_key
    submitted_onboarding
    is_system
    parent_merchant_uid
    card_active
    ach_active
    cash_active
    fee_model {
      merchant_fee {
        card_basis
        card_fixed
        ach_basis
        ach_fixed
      }
      service_fee {
        card_basis
        card_fixed
        ach_basis
        ach_fixed
      }
      service_fee_min {
        card_basis
        card_fixed
        ach_basis
        ach_fixed
      }
    }
  }
}
`

export const query = (uid) => {
  const variables = {
    merchant_uid: uid
  }
  return appSyncQuery(queryString, variables)
}

const createString = `mutation CreateMerchant($phone: AWSPhone, $last_name: String!, $first_name: String!, $email: AWSEmail!, $parent_merchant_uid: String, $merchant_name: String! ) {
  createMerchant(merchant_name: $merchant_name, parent_merchant_uid: $parent_merchant_uid, user: {email: $email, first_name: $first_name, last_name: $last_name, phone: $phone}) {
    ach_active
    card_active
    cash_active
    merchant_name
    merchant_uid
    parent_merchant_uid
    submitted_onboarding
  }
}
`

export const create = (name, parent, firstName, lastName, email, phone) => {
  const variables = {
    merchant_name: name,
    parent_merchant_uid: parent,
    first_name: firstName,
    last_name: lastName,
    email: email,
    phone: phone ? `+1${phone}` : null,
  }
  return appSyncQuery(createString, variables)
}

export const getNonChildrenMerchants = `query getNonChildrenMerchants($query: SqlQuery, $limit: Int, $offset: String, $offset_id: String) {
  merchants(query: $query, limit: $limit, offset: $offset, offset_id: $offset_id) {
    items {
      merchant_name
      merchant_uid
    }
    total_row_count
  }
}

`