import { appSyncQuery } from './index';

// export const list = (userPool, merchant) => {
//   return `{ users(userPoolId: "${userPool}"${merchant ? `, merchant: "${merchant}"` : ''}) { username, enabled, userStatus, email, fullName, phone } }`
// }

// export const create = (userPool, firstName, lastName, email, phone, merchant) => {
//   return `mutation { createUser(userPoolId: "${userPool}", firstName: "${firstName}", lastName: "${lastName}", email: "${email}"${phone ? `, phone: "+1${phone}"` : ''}${merchant ? `, merchant: "${merchant}"` : ''}) { createdUser { username, enabled, userStatus, email } } }`
// }

// export const deleteUser = (userPool, username) => {
//   return `mutation { deleteUser(userPoolId: "${userPool}", username: "${username}") { deleteResponse { username, message } } }`
// }

const listString = `query listUsers($merchant_uid: String, $user_pool: UserPool!) {
  users(user_pool: $user_pool, merchant_uid: $merchant_uid) {
    username
    email
    user_status
    full_name
    phone
  }
}`

export const list = (userPool, merchant) => {
  const variables = {
    user_pool: userPool,
    merchant_uid: merchant,
  }
  return appSyncQuery(listString, variables)
}

const createString = `mutation MyMutation($user_pool: UserPool!, $phone: AWSPhone, $merchant_uid: String, $last_name: String!, $first_name: String!, $email: AWSEmail!) {
  createUser(input: {
                email: $email, 
                first_name: $first_name, 
                last_name: $last_name, 
                merchant_uid: $merchant_uid, 
                phone: $phone, 
                user_pool: $user_pool}) {
    username
    email
    user_status
    full_name
    phone
  }
}`

export const create = (userPool, firstName, lastName, email, phone, merchant) => {
  const variables = {
    user_pool: userPool,
    first_name: firstName,
    last_name: lastName,
    email: email,
    phone: phone ? `+1${phone}` : null,
    merchant_uid: merchant,
  }
  return appSyncQuery(createString, variables)
}

const deleteString = `mutation MyMutation($user_pool: UserPool = MERCHANT, $username: String = "") {
  deleteUser(user_pool: $user_pool, username: $username)
}`

export const deleteUser = (userPool, username) => {
  const variables = {
    user_pool: userPool,
    username: username,
  }
  return appSyncQuery(deleteString, variables)
}